.btn {
    font-family: $font-family-sans-serif;
    border: 0;
    border-radius: 50px;
    width: 163px;
    padding: 13px 8px 13px 8px;
    font-weight: 700;
    font-size: 18px;
    color: white;
    text-decoration: none;
}

@include media-breakpoint-down(sm) {
    .btn {
        font-family: $font-family-sans-serif;
        border: 0;
        border-radius: 50px;
        width: 113px;
        padding: 10px 8px 10px 8px;
        font-weight: 700;
        font-size: 0.9rem;
        color: white;
    }
}

.btn-col {
    font-family: $font-family-sans-serif;
    border: 0;
    border-radius: 50px;
    width: 250px;
    padding: 13px 8px 13px 8px;
    font-weight: 700;
    font-size: 18px;
    color: white;
    text-decoration: none;
}
