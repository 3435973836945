.smaller-text {
  font-size: 0.875rem;
}
p {
  font-weight: 400;
  font-size: 1rem;
  color: #34344A;
}
h1 {
  color: #3D5467 !important;
  font-weight: 700;
}
h5 {
  color: white !important;
}
h3 {
  font-size: 23px;
  color: #0F0E63 !important;
}
h2 {
  font-size: 32px !important;
  color: rgba(61, 84, 103, 1) !important;
  font-weight: 700;

}
h4 {
  font-size: 18px !important;
  color: rgba(61, 84, 103, 1) !important;
  font-weight: 900;
  text-decoration: underline;

}
a {
  color: rgba(219, 84, 97, 1);
  font-weight: 700;
  text-decoration: underline;
}
a:hover {
  text-decoration: none;
  color: rgba(219, 84, 97, 1);

}
.subpage-header-text{
font-size: 18px;
font-weight: 600;
color: #243B51;
}

%heading {
  margin-bottom: $spacer / 2;
  word-break: break-word;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  @extend %heading;
  &:not(.has-text-color) {
    color: inherit;
  }
}
@each $level, $size in $headings {
  #{$level} {
    font-size: calc(#{$size} * 0.8);
    line-height: $size;
    @include media-breakpoint-up("md") {
      font-size: $size;
      line-height: calc(#{$size} * 1.2);
    }
  }
}

.text-decoration-underline{
  text-decoration: underline;
}
.font-weight-bold{
  text-decoration: bold;
  color: #0F0E63;
}