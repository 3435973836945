.card-header {
  padding: 1.1rem 1.25rem;
  margin-bottom: 0;
  background-color: #f19ea8;

  border-radius: 8px;
}
.card {
  border: 0px;
  margin-bottom: 10px;
  border-radius: 8px;
}
.card-header:first-child {
  border: 0px;
  border-radius: 8px;
}
.card-link {
  text-decoration: none;
  color: rgba(15, 14, 99, 1);
}
.card-link:hover {
  text-decoration: underline;
  color: rgba(15, 14, 99, 1);
}

.panel-heading {
  background: #f19ea8;
  padding: 25px 24px;
  position: relative;
  border-radius: 8px;
}
.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.fa-chevron-down {
  position: absolute;
  right: 20px;
}
.panel-heading .panel-title a:hover {
  text-decoration: none;
}
.panel-heading .panel-title a {
  margin-right: 10px;
  display: block;
  text-decoration: none;
  color: rgba(61, 84, 103, 1) !important;
  font-size: 22px !important;

}

.panel-body {
  background: #f7f7f7;
  padding: 18px 24px;
  margin-top: 5px;
  border-radius: 8px;
}
