footer {
    bottom: 0;
    width: 100%;
    height: 3.5rem;
    background-color: #3d5467;
    position: absolute;
    left: 0;
    overflow: hidden;
}
footer p {
    color: white;
    font-weight: 500;
}