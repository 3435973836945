.sidebar-section {
    position: absolute;
    height: 100%;
    width: 100%;
}

.sidebar-item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
@include media-breakpoint-down(lg) {
    .sidebar-item {
        display: none;
    }
}
.make-me-sticky {
    top: 0;
    padding: 20px 15px;
}

.sidebar-item {
    text-align: left;

    .make-me-sticky-content {
        background: white;
        max-width: 100%;
        margin: 0 auto;
        padding: 50px 0 100px;
    }
}
