.icon-list {
  padding-left: 0;
  list-style: none;
}
.icon-list li {
  display: flex;
  align-items: flex-start;
  margin-bottom: 0.25rem;
}

.list-menu::before {
  content: url("/assets/img/list-icon.png");
  padding-right: 20px;
  position: relative;
  top: 5px;
  left: 5px;
}
li {
  display: block;
  padding-bottom: 20px;
}
ul {
  padding: 0;
}
.logo-list{
  display: flex;
}
.logo-list img{
  max-width: 120px;
  padding-left: 20px;
}