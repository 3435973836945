@import "bootstrap/scss/mixins";

.container {
    max-width: 1200px;
}
.bg-container {
    background-color: #e5e5e5;
    width: 100%;
}
.bi {
    fill: currentColor;
}
.page-container {
    position: relative;
    min-height: 100vh;
}
html {
    position: relative;
    min-height: 100%;
}
body {
    margin-top: 60px;
    background-color: white;
}
.content-wrapper {
    margin-bottom: auto;
}

article {
    background-color: #e5e5e5;
    padding-top: 50px;
    padding-bottom: 100px;
}
.content-section h2 {
    text-align: center;
    margin: 0;
}
hr {
    border-bottom: 4px solid rgba(219, 84, 97, 0.4);
    border-top: 0px;
}
hr .dropdown {
    border: 1px solid rgba(219, 84, 97, 0.4);
}
.fa-check{
    padding-right: 20px;
    color: rgba(219, 84, 97, 0.4);
}
.fa-circle{
    padding-right: 20px;
    color: rgba(219, 84, 97, 0.4);
}
.badge-red {
    background-color: rgb(219, 84, 98);
    font-size: 14px;
    line-height: 3;
    padding: .475rem .8625rem;
    font-weight: 700;
    border-radius: 5rem;
    margin-right: 20px;
    color: #ffffff;
   
}
.list-padding{
    margin-top: -24px;
    margin-bottom: -5px;
}
