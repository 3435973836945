.table thead > tr > th,
.table tbody > tr > th,
.table tfoot > tr > th,
.table thead > tr > td,
.table tbody > tr > td,
.table tfoot > tr > td {
    border-right: none;
    border-left: none;
    border-bottom: 2px solid rgba(61, 84, 103, 0.25);
}
.table2 thead > tr > th,
.table2 tbody > tr > th,
.table2 tfoot > tr > th,
.table2 thead > tr > td,
.table2 tbody > tr > td,
.table2 tfoot > tr > td {
    border-top: 1px solid rgba(61, 84, 103, 0.25);
}
thead th {
    background-color: rgba(61, 84, 103, 1);
    font-size: 1.3rem;
    font-weight: 700;
    color: white;
    text-align: center;
    padding: 10px !important;
    height: 57px;
    font-family: $font-family-monospace;
}
td {
    font-family: $font-family-sans-serif;
    color: rgba(61, 84, 103, 1);
    font-size: 1rem;
    text-align: center;
    vertical-align: middle;
}
@include media-breakpoint-down(sm) {
    .table-d-none {
        display: none;
    }
}

@include media-breakpoint-down(sm) {
    .img-responsive {
        max-width: 120px !important;
        height: auto;
    }
}
@include media-breakpoint-up(md) {
    .img-responsive {
        max-width: 160px;
        height: auto;
    }
}
@include media-breakpoint-down(sm) {
    .img-responsive-mini {
        max-width: 70px !important;
        height: auto;
    }
}