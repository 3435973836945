.card {
    border-radius: 0 !important;
    background-color: #fff;
    padding: -20px;

}
.card-text {
    color: white !important;
    font-family: $font-family-sans-serif;
}
.card-title {
    color: white !important;
    font-family: $font-family-monospace;
}
.card-grey {
    border-radius: 0 !important;
    background-color: #EFEFF3;
    padding: 6px;
    border-left: 6px solid rgba(61, 84, 103, 1);
}
.card-grey-text {
    color: rgba(38, 38, 38, 1) !important;
    font-family: $font-family-sans-serif;
}
.card-grey-title {
    color: rgba(219, 84, 97, 1) !important;
    font-family: $font-family-monospace;
    font-weight: 700;
    font-size: 28px;
}
.logo-img-card {
    max-height: 65px;
    max-width: 300px;
}
.card-img-top-logo {
    border-radius: 0;
    height: 80px;
}